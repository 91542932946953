import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withTranslation} from 'react-i18next';
import FormTextField from '../form/FormTextField';
import { Button } from '@material-ui/core';
import FormCheckBox from "../form/FormCheckBox";

let BikeTypeForm = ({ handleSubmit, submitting, t }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
          name="name.pl"
          component={FormTextField}
          label="Nazwa (PL)"
          required
        />
      </div>
      <div>
        <Field
          name="description.pl"
          component={FormTextField}
          label="Opis (PL)"
          fullWidth
          required
        />
      </div>
      <div>
        <Field
          name="name.en"
          component={FormTextField}
          label="Nazwa (EN)"
          required
        />
      </div>
      <div>
        <Field
          name="description.en"
          component={FormTextField}
          label="Opis (EN)"
          fullWidth
          required
        />
      </div>
      <div>
        <Field
          name="name.de"
          component={FormTextField}
          label="Nazwa (DE)"
          required
        />
      </div>
      <div>
        <Field
          name="description.de"
          component={FormTextField}
          label="Opis (DE)"
          fullWidth
          required
        />
      </div>
      <div>
        <Field
          name="isElectric"
          component={FormCheckBox}
          label="Rower elektryczny"
        />
      </div>
      <div style={{textAlign: 'right', marginTop: 16}}>
        <Button variant="contained" color="primary" type="submit" className="signin-button" disabled={submitting}>
          {t('bikeTypes.submitButton')}
        </Button>
      </div>
    </form>
  );
};

BikeTypeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  submitting: PropTypes.bool
};

BikeTypeForm = reduxForm({
  form: 'bikeTypeForm',
})(BikeTypeForm);

export default withTranslation()(BikeTypeForm);
