import { AuthModel } from '../models/auth/AuthModel';
import { BikesListModel } from '../models/bikes/BikesListModel';
import { MobileUserAccountsListModel } from '../models/mobileUserAccounts/MobileUserAccountsListModel';
import { TenantsListModel } from '../models/tenants/TenantsListModel';
import {WithdrawalModel} from "../models/WithdrawalModel";

export default {
  auth: new AuthModel(null),
  notifications: [],
  bikes: {
    list: new BikesListModel(),
  },
  virtualLocks: {
    data: [],
    fetching: false,
  },
  bikeTypes: {
    data: [],
    fetching: false,
  },
  bikeParts: {
    data: [],
    fetching: false,
  },
  mobileUserAccounts: {
    list: new MobileUserAccountsListModel(),
  },
  mobileUserAccountDetails: {
    data: null,
    fetching: true,
  },
  tenants: {
    list: new TenantsListModel(),
  },
  tenantDetails: {
    data: null,
    fetching: true,
  },
  reports: {
    templateList: [],
    fetchingTemplateList: true,
    submitting: false,
    reportConfigurationList: [],
    fetchingReportConfigurationList: true,
    reportConfigurationHistory: null,
    fetchingReportConfigurationHistory: true,
    fetchingWithdrawalReport:false,
    withdrawalsData: new WithdrawalModel(),
    changeWithdrawalStatusSubmitting: false,
  },
  publicAccessToken: {
    showNewTokenDialog: false,
    showCreatedTokenDialog: false,
    accessToken: null,
    accessTokenList: [],
    fetchingAccessTokenList: true,
    submitting: false,
    permissions: [],
    fetchingPermissions: false,
    tokenDialog: {
      show: false,
      edit: false,
      data: null
    },
    deactivateTokenDialog: {
      show: false,
      data: null
    }
  }
};
