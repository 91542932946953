import apiBase from "./apiBase";

class ReportsApi {
  static getReportsTemplates(tenantId) {
    return apiBase.create().get(`/reportTemplate/${tenantId}`);
  }
  static getReportsConfigurations(tenantId) {
    return apiBase.create().get(`/reportConfigurations/${tenantId}`);
  }

  static updateReportsConfigurations(tenantId, data) {
    return apiBase.create().put(`/reportConfigurations/${tenantId}`, data);
  }
  static getReportsConfigurationsHistory(tenantId,reportId) {
    return apiBase.create().get(`/reportConfigurations/history/${tenantId}/${reportId}`);
  }
  static deactivateReportsConfigurations(tenantId, id) {
    return apiBase.create().delete(`/deactivateReportConfigurations/${tenantId}/${id}`);
  }
  static activateReportsConfigurations(tenantId, id) {
    return apiBase.create().post(`/activateReportConfigurations/${tenantId}/${id}`);
  }
  static downloadPdf(tenantId, reportId) {
    return apiBase.create().get(`/reports/${tenantId}/${reportId}/download/pdf`);
  }
  static downloadXlsx(tenantId, reportId) {
    return apiBase.create().get(`/reports/${tenantId}/${reportId}/download/xlsx`);
  }
  static generateWithdrawalFormReport(data) {
    return apiBase.create().post(`/withdrawFormReport/export`, data, {
      responseType: "blob",
      timeout: 120000,
    });
  }
  static withdrawalFormChangeStatus(id, data) {
    return apiBase.create().post(`/withdrawFormReport/changeStatus/${id}`, data, );
  }
  static withdrawalFormReportList(pageNumber, pageSize, sortBy, filterBy) {
    const payload = {
      paging: {
        pageNumber,
        pageSize,
      },
      filterBy: Object.assign(
        {},
        ...filterBy.map((x) => ({ [x.id]: x.value })),
      ),
      sortBy: Object.assign({}, ...sortBy.map((x) => ({ [x.id]: x.value }))),
    };
    return apiBase.create().post(`/withdrawFormReport/list`, payload);
  }
  static createReportConfigurations(data, tenantId) {
    const payload = {
      ...data,
      tenantId
    }
    return apiBase.create().post(`/createReportConfigurations/`, payload);
  }
}

export default ReportsApi;
